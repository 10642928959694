<template>
  <div class="our">
    <!-- 宗旨 -->
    <div class="purpose clear">
      <div class="info">
        <div class="title">我们的宗旨</div>
      </div>
      <div class="txt">
        1
      </div>
    </div>
    <hr />

    <!-- 定位 -->
    <div class="purpose">
      <div class="txt">
        1
      </div>
      <div class="info">
        <div class="title">我们的定位</div>
      </div>
    </div>
    <hr />

    <!-- 团队 -->
    <div class="team clear">
      <div class="team_info">
        <p class="use_txt">我们的团队</p>
        <div class="jhr">
          聚合荣技术团队有着5年的技术成次和积累，其中包
          含js,python,c++,php,wordpress等最新，最广泛的
          语言，有能力为客户提供多方位的技术开发业务，特
          别是在应用软件开发，海外软件代理开发，控制系统
          开发等领域有突出的优势，我们相信以信息技术为基
          础的人工智能，大数据，云计算代表了未来科技创新
          行业发展的浪潮，聚合荣勇于参与和推动科技进步并
          成为其中的一员，为全球客户提供高品质的开发服务
        </div>
      </div>
      <img class="team_img" src="../assets/img/indexImg/team.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "our"
}
</script>

<style scoped>
@import url('../assets/css/our.css');
</style>